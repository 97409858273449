<template>
  <div id="record-api" v-loading="loading">
    <div class="cm-content">
      <el-table :data="data">
        <el-table-column label="Token" prop="token">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.token.length > 20"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.token"
            >
              <div slot="reference">{{ scope.row.token.substr(0, 20) }}...</div>
            </el-popover>
            <div v-else>
              {{ scope.row.token | formatNullValue }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Body" prop="body">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.body.length > 20"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.body"
            >
              <div slot="reference">{{ scope.row.body.substr(0, 20) }}...</div>
            </el-popover>
            <div v-else>
              {{ scope.row.body | formatNullValue }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="方法" prop="request_method"></el-table-column>
        <el-table-column label="URI" prop="request_uri">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.request_uri.length > 20"
              placement="top-start"
              width="180"
              trigger="hover"
              :content="scope.row.request_uri"
            >
              <div slot="reference">
                {{ scope.row.request_uri.substr(0, 20) }}...
              </div>
            </el-popover>
            <div v-else>
              {{ scope.row.request_uri | formatNullValue }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态码" prop="status_code"></el-table-column>
        <el-table-column label="耗时" prop="latency_time"></el-table-column>
        <el-table-column label="品牌" prop="brand"></el-table-column>
        <el-table-column label="平台" prop="platform"></el-table-column>
        <el-table-column label="系统" prop="system"></el-table-column>
        <el-table-column label="版本" prop="version"></el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import record from "@/api/record";

export default {
  name: "record-api",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      this.loading = true;
      record.listApi(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "record-api",
          query: params,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "record-api") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#record-api {
  display: flex;
  height: 100%;
}
</style>
